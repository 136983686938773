import { cn } from '@hapstack/common'
import type { ComponentProps } from 'react'
import { createContext, useContext, useMemo } from 'react'
import type { z } from 'zod'

import { useSearchParams } from '../../hooks/useSearchParams'

type Props<T extends z.ZodObject<any>> = ComponentProps<'div'> & {
  filterSchema: T
  activeFilters?: (filters: z.infer<T>) => React.ReactNode
}

type DataFiltersContextType<T extends z.ZodObject<any>> = {
  filters: T
}

const DataFiltersContext = createContext<
  DataFiltersContextType<any> | undefined
>(undefined)

// A custom hook to ensure type safety and ease of use
export function useDataFiltersContext<T extends z.ZodObject<any>>() {
  const context = useContext(
    DataFiltersContext as React.Context<DataFiltersContextType<T> | undefined>
  )
  if (!context) {
    throw new Error(
      'useDataFiltersContext must be used within a DataFiltersProvider'
    )
  }
  return context
}

const DataFilters = <T extends z.ZodObject<any>>({
  className,
  children,
  filterSchema,
  activeFilters,
  ...props
}: Props<T>) => {
  const { searchParams, removeSearchParam, getSearchParamsObject } =
    useSearchParams()

  const filters = useMemo(() => {
    const params = getSearchParamsObject()
    return filterSchema.parse(params) as T
  }, [filterSchema, getSearchParamsObject])

  const filtersAreActive = useMemo(
    () => hasAtLeastOneActiveFilter(searchParams, filters),
    [searchParams, filters]
  )

  function resetFilters() {
    Object.keys(filters).map((filter) => removeSearchParam(filter))
  }

  return (
    <DataFiltersContext.Provider value={{ filters }}>
      <div
        className={cn('flex flex-col gap-4', className)}
        {...props}
      >
        <div className={'flex items-center justify-between gap-4'}>
          <div className={'flex w-full items-center justify-between gap-4'}>
            <div className="flex items-center gap-2">
              {children}
              {filtersAreActive ? (
                <button
                  onClick={resetFilters}
                  className="shrink-0 text-sm font-medium text-muted-foreground underline"
                >
                  Reset filters
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {activeFilters ? (
          <div className="flex items-center gap-2">
            {activeFilters(filters)}
          </div>
        ) : null}
      </div>
    </DataFiltersContext.Provider>
  )
}

function hasAtLeastOneActiveFilter<T extends z.ZodObject<any>>(
  searchParams: URLSearchParams,
  filters: T
) {
  for (const key in filters) {
    const param = searchParams.get(key)
    if (param) return true
  }
  return false
}

export { DataFilters }
