import { cn } from '@hapstack/common'
import type { LinkProps } from '@remix-run/react'
import { Link } from '@remix-run/react'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

const linkStyles = cva('underline-offset-2 decoration-current', {
  variants: {
    variant: {
      button:
        'px-2 py-1.5 rounded hover:bg-secondary transition-colors flex items-center gap-2 w-fit',
      underline: 'underline',
      'underline-on-hover': 'hover:underline',
      color: 'text-accent font-bold hover:underline',
    },
  },
  defaultVariants: {
    variant: 'color',
  },
})

type TextLinkProps = VariantProps<typeof linkStyles>

export const TextLink = forwardRef<
  HTMLAnchorElement,
  TextLinkProps & LinkProps
>(({ variant, className, ...props }, forwardedRef) => {
  return (
    <Link
      {...props}
      prefetch={props.prefetch || 'intent'}
      className={cn(linkStyles({ variant }), className)}
      ref={forwardedRef}
    >
      {props.children}
    </Link>
  )
})

TextLink.displayName = 'TextLink'
