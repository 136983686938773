import { cn } from '@hapstack/common'
import {
  Button,
  Calendar,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@hapstack/ui'
import { format, subMonths, subWeeks } from 'date-fns'
import { useMemo } from 'react'
import { z } from 'zod'

import { useSearchParams } from '../../hooks/useSearchParams'

export const dateFilterParamsSchema = z.object({
  startDate: z
    .string()
    .optional()
    .transform((v) => (v ? new Date(v) : undefined)),
  endDate: z
    .string()
    .optional()
    .transform((v) => (v ? new Date(v) : undefined)),
})

export const DateRangeFilter = ({
  defaultMonth = subMonths(new Date(), 1),
}: {
  defaultMonth?: Date
}) => {
  const { setSearchParam, removeSearchParam, getSearchParamsObject } =
    useSearchParams()

  const activeFilters = useMemo(() => {
    const params = getSearchParamsObject()
    return dateFilterParamsSchema.parse(params)
  }, [getSearchParamsObject])

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="white"
          size="sm"
          className="flex items-center gap-1"
        >
          <Icon
            name="calendar"
            className={cn(
              (activeFilters.startDate || activeFilters.endDate) &&
                'text-accent'
            )}
          />
          Date range
          <Icon
            name="chevron-down"
            size="sm"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="range"
          defaultMonth={defaultMonth}
          numberOfMonths={2}
          selected={{
            from: activeFilters.startDate || subWeeks(new Date(), 1),
            to: activeFilters.endDate,
          }}
          onSelect={(date) => {
            if (!date) return

            if (date.from) {
              const startDate = format(date.from, 'P')
              setSearchParam('startDate', startDate)
            } else {
              removeSearchParam('startDate')
            }

            if (date.to) {
              const endDate = format(date.to, 'P')
              setSearchParam('endDate', endDate)
            } else {
              removeSearchParam('endDate')
            }
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
