// Most of this file has been adapted from:
// https://github.com/Code-Forge-Net/remix-hook-form/blob/main/src/utilities/index.ts

import {
  type Failure,
  type InputError,
  isEnvironmentError,
  isInputError,
} from 'composable-functions'
import type { FieldError, FieldErrors, FieldValues } from 'react-hook-form'

const tryParseJSON = (jsonString: string) => {
  if (jsonString === 'undefined') return undefined

  try {
    const json = JSON.parse(jsonString.trim())

    return json
  } catch (e) {
    return jsonString
  }
}

/**
 * Generates an output object from the given form data, where the keys in the output object retain
 * the structure of the keys in the form data. Keys containing integer indexes are treated as arrays.
 *
 * @param {FormData} formData - The form data to generate an output object from.
 * @param {boolean} [preserveStringified=false] - Whether to preserve stringified values or try to convert them
 * @returns {Object} The output object generated from the form data.
 */
export const generateFormData = (
  formData: FormData | URLSearchParams,
  preserveStringified = false
) => {
  // Initialize an empty output object.
  const outputObject: Record<any, any> = {}

  // Iterate through each key-value pair in the form data.
  for (const [key, value] of formData.entries()) {
    // Try to convert data to the original type, otherwise return the original value
    const data = preserveStringified ? value : tryParseJSON(value.toString())
    // Split the key into an array of parts.
    const keyParts = key.split('.')
    // Initialize a variable to point to the current object in the output object.
    let currentObject = outputObject

    // Iterate through each key part except for the last one.
    for (let i = 0; i < keyParts.length - 1; i++) {
      // Get the current key part.
      const keyPart = keyParts[i] as string
      // If the current object doesn't have a property with the current key part,
      // initialize it as an object or array depending on whether the next key part is a valid integer index or not.

      if (!currentObject[keyPart]) {
        currentObject[keyPart] = /^\d+$/.test(keyParts[i + 1] as string)
          ? []
          : {}
      }
      // Move the current object pointer to the next level of the output object.
      currentObject = currentObject[keyPart]
    }

    // Get the last key part.
    const lastKeyPart = keyParts[keyParts.length - 1] as string
    const lastKeyPartIsArray = /\[\d*\]$|\[\]$/.test(lastKeyPart)

    // Handles array[] or array[0] cases
    if (lastKeyPartIsArray) {
      const key = lastKeyPart.replace(/\[\d*\]$|\[\]$/, '')
      if (!currentObject[key]) {
        currentObject[key] = []
      }

      currentObject[key].push(data)
    }

    // Handles array.foo.0 cases
    if (!lastKeyPartIsArray) {
      // If the last key part is a valid integer index, push the value to the current array.
      if (/^\d+$/.test(lastKeyPart)) {
        currentObject.push(data)
      }
      // Otherwise, set a property on the current object with the last key part and the corresponding value.
      else {
        currentObject[lastKeyPart] = data
      }
    }
  }

  // Return the output object.
  return outputObject
}

/**
  Creates a new instance of FormData with the specified data and key.
  @template T - The type of the data parameter. It can be any type of FieldValues.
  @param {T} data - The data to be added to the FormData. It can be either an object of type FieldValues.
  @param {boolean} stringifyAll - Should the form data be stringified or not (default: true) eg: {a: '"string"', b: "1"} vs {a: "string", b: "1"}
  @returns {FormData} - The FormData object with the data added to it.
*/
export const createFormData = <T extends FieldValues>(
  data: T,
  stringifyAll = true
): FormData => {
  const formData = new FormData()
  if (!data) {
    return formData
  }
  Object.entries(data).map(([key, value]) => {
    if (value instanceof FileList) {
      for (let i = 0; i < value.length; i++) {
        formData.append(key, value[i] as File)
      }
      return
    }
    if (value instanceof File || value instanceof Blob) {
      formData.append(key, value)
    } else {
      if (stringifyAll) {
        formData.append(key, JSON.stringify(value))
      } else {
        if (typeof value === 'string') {
          formData.append(key, value)
        } else if (value instanceof Date) {
          formData.append(key, value.toISOString())
        } else {
          formData.append(key, JSON.stringify(value))
        }
      }
    }
  })

  return formData
}

/**
Parses the specified Request object's FormData to retrieve the data associated with the specified key.
Or parses the specified FormData to retrieve the data 
@template T - The type of the data to be returned.
@param {Request | FormData} request - The Request object whose FormData is to be parsed.
@param {boolean} [preserveStringified=false] - Whether to preserve stringified values or try to convert them
@returns {Promise<T>} - A promise that resolves to the data of type T.
@throws {Error} - If no data is found for the specified key, or if the retrieved data is not a string.
*/
export const parseFormData = async (
  request: Request | FormData,
  preserveStringified = false
) => {
  const formData =
    request instanceof Request ? await request.clone().formData() : request
  return generateFormData(formData, preserveStringified)
}

/**
Converts domain function errors to React Hook Form errors.
@param {ErrorResult} errorResult - A domain function error result object.
@returns {FieldErrors} - An object containing React Hook Form friendly errors.
*/
export const formatErrors = (errorResult: Failure): FieldErrors => {
  const result: FieldErrors = {}

  const inputErrors = errorResult.errors.filter(isInputError) as InputError[]

  inputErrors.forEach((inputError) => {
    let currentLevel = result

    inputError.path.forEach((key, index) => {
      // Check if we're at the last key in the path; if so, assign the message
      if (index === inputError.path.length - 1) {
        currentLevel[key] = { message: inputError.message } as FieldError
      } else {
        // If not at the last key, either create a new object at this key or move to the next level
        currentLevel[key] = currentLevel[key] || {}
        currentLevel = currentLevel[key] as any
      }
    })
  })

  const exceptions = errorResult.errors.filter(
    (e) => !isInputError(e) && !isEnvironmentError(e)
  )

  if (exceptions.length) {
    result.root = {
      message: exceptions.at(0)?.message,
    } as FieldError
  }

  return result
}
