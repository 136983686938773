import { cn } from '@hapstack/common'

export const SkeletonBar = ({
  width = 100,
  height = 20,
  ...props
}: { height?: number; width?: number } & JSX.IntrinsicElements['div']) => (
  <div
    className={cn('h-5 w-full rounded bg-border', props.className)}
    style={{ width: `${width}%`, height: `${height}px` }}
  ></div>
)
