import { Icon, Input, Spinner } from '@hapstack/ui'
import { type ComponentProps, forwardRef, useEffect, useState } from 'react'

import { useDebounce } from '../hooks'
import { useFormContext } from './useForm'

type Props = {
  debounce?: number
} & ComponentProps<typeof Input>

export const FormAutosaveInput = forwardRef<
  React.ElementRef<typeof Input>,
  Props
>(({ debounce = 800, ...props }, ref) => {
  const [success, setSuccess] = useState(false)

  const form = useFormContext()

  const debouncedSubmit = useDebounce(form.handleSubmit, debounce)

  useEffect(() => {
    if (!form.formState.isSubmitSuccessful) return

    setSuccess(true)

    const timeout = setTimeout(() => {
      setSuccess(false)
    }, 4000)

    return () => clearTimeout(timeout)
  }, [form.formState.isSubmitSuccessful])

  return (
    <div className="relative">
      <Input
        ref={ref}
        {...props}
        onChange={(e) => {
          props.onChange?.(e)
          debouncedSubmit()
        }}
      />
      <div className="absolute right-3 top-0 flex h-full items-center justify-center">
        {form.formState.isSubmitting ? (
          <Spinner />
        ) : success ? (
          <Icon
            name="check-circle-2"
            className="text-accent"
            size="lg"
          />
        ) : null}
      </div>
    </div>
  )
})

FormAutosaveInput.displayName = 'FormAutosaveInput'
