import { TextSkeleton } from '@hapstack/ui'
import { Await } from '@remix-run/react'
import type { Failure, Result, Success } from 'composable-functions'
import {
  Suspense as ReactSuspense,
  type SuspenseProps as ReactSuspenseProps,
} from 'react'

type SuspenseProps<T> = Omit<ReactSuspenseProps, 'children'> & {
  promise: Promise<Result<T>>
  onError?: (error: Failure) => void
  children: (data: Success<T>['data']) => React.ReactNode
}

export const SuspenseWrapper = <T extends object | number | null>({
  fallback = DefaultSkeleton,
  promise,
  onError,
  children,
  ...props
}: SuspenseProps<T>) => {
  return (
    <ReactSuspense
      fallback={fallback}
      {...props}
    >
      <Await resolve={promise}>
        {(result) => {
          if (!result.success) {
            result.errors.forEach(console.error)

            if (onError) {
              onError(result)
            }

            throw new Error(
              'An error occurred while loading data for this page.'
            )
          }

          return children(result.data)
        }}
      </Await>
    </ReactSuspense>
  )
}

const DefaultSkeleton = (
  <div className="space-y-8">
    {Array.from({ length: 3 }, (_, i) => (
      <TextSkeleton key={i} />
    ))}
  </div>
)
