import { z } from 'zod'

export const TABLE_PARAM_DELIMITER = '.'

export function parseTableQueryParam<T extends string>(
  value: T | null | undefined,
  type?: 'string'
): T[] | undefined

export function parseTableQueryParam<T extends string>(
  value: T | null | undefined,
  type?: 'boolean'
): boolean[] | undefined

export function parseTableQueryParam<T extends string>(
  value: T | null | undefined,
  type?: 'number'
): number[] | undefined

export function parseTableQueryParam<T extends string>(
  value: T | null | undefined,
  type: 'string' | 'boolean' | 'number' = 'string'
) {
  if (!value) return
  const array = value.split(TABLE_PARAM_DELIMITER) as T[]
  if (type === 'string') return array
  if (type === 'boolean') return array.map((v) => v === 'true')
  if (type === 'number') return array.map((v) => Number(v))
}

export const tableParamsSchema = z.object({
  page: z.coerce.number().catch(1),
  pageSize: z.coerce.number().optional(),
  sortBy: z.string().optional(),
  order: z.enum(['asc', 'desc']).catch('asc'),
  query: z.string().optional(),
})
