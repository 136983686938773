import type { FieldValues, UseFormReturn } from 'react-hook-form'
import { FormProvider as ReactHookFormProvider } from 'react-hook-form'

interface FormProviderProps<T extends FieldValues>
  extends Omit<UseFormReturn<T>, 'handleSubmit' | 'reset'> {
  children: React.ReactNode
  handleSubmit: any
  register: any
  reset: any
}

export const FormProvider = <T extends FieldValues>({
  children,
  ...props
}: FormProviderProps<T>) => {
  return <ReactHookFormProvider {...props}>{children}</ReactHookFormProvider>
}
