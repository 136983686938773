import { cn } from '@hapstack/common'

import { SkeletonBar } from './SkeletonBar'

export const TextSkeleton = (props: JSX.IntrinsicElements['div']) => (
  <div
    {...props}
    className={cn('w-full animate-pulse space-y-2', props.className)}
  >
    <SkeletonBar
      width={60}
      height={16}
    />
    <SkeletonBar
      width={80}
      height={16}
    />
    <SkeletonBar
      width={30}
      height={16}
    />
  </div>
)
