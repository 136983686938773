// Adapted from: https://github.com/Code-Forge-Net/remix-hook-form/blob/main/src/hook/index.tsx

import { zodResolver } from '@hookform/resolvers/zod'
import type { SubmitFunction } from '@remix-run/react'
import {
  useActionData,
  useFetcher,
  useNavigation,
  useSubmit,
} from '@remix-run/react'
import { useEffect, useMemo, useState } from 'react'
import type {
  DefaultValues,
  FieldValues,
  FormState,
  KeepStateOptions,
  Path,
  RegisterOptions,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form'
import {
  useForm as useReactHookForm,
  useFormContext as useReactHookFormContext,
} from 'react-hook-form'
import { z } from 'zod'

import { createFormData } from './utils'

export type SubmitFunctionOptions = Parameters<SubmitFunction>[1]

export interface UseRemixFormOptions<T extends FieldValues>
  extends Omit<UseFormProps<T>, 'resolver'> {
  intent: string
  submitHandlers?: {
    onValid?: SubmitHandler<T>
    onInvalid?: SubmitErrorHandler<T>
  }
  submitConfig?: SubmitFunctionOptions
  submitData?: FieldValues
  useFetcher?: boolean
  fetcherKey?: string
  schema?: z.ZodSchema<T>
  onSuccess?: (data: any, form: UseFormReturn<T>) => void
  onError?: () => void
  /**
   * If true, all values will be stringified before being sent to the server, otherwise everything but strings will be stringified (default: false)
   */
  stringifyAllValues?: boolean
}

export const useForm = <T extends FieldValues>({
  intent,
  submitHandlers,
  submitConfig,
  submitData,
  useFetcher: shouldUseFetcher = false,
  fetcherKey,
  schema,
  onSuccess,
  onError,
  stringifyAllValues = false,
  ...formProps
}: UseRemixFormOptions<T>) => {
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false)
  const actionSubmit = useSubmit()
  const actionData = useActionData<unknown>()
  const fetcher = useFetcher<unknown>({ key: fetcherKey || intent })
  const submit = shouldUseFetcher ? fetcher.submit : actionSubmit
  const data: any = shouldUseFetcher ? fetcher.data : actionData

  const methods = useReactHookForm<T>({
    ...formProps,
    resolver: zodResolver(schema || z.any()),
    errors: data?.errors,
  })
  const navigation = useNavigation()

  // Either it's submitted to an action or submitted to a fetcher (or neither)
  const isSubmittingForm = useMemo(
    () =>
      (navigation.state !== 'idle' &&
        navigation.formData?.get('intent') === intent) ||
      (shouldUseFetcher &&
        fetcher.state !== 'idle' &&
        fetcher.formData?.get('intent') === intent),
    [navigation.state, navigation.formData, shouldUseFetcher, fetcher, intent]
  )

  // Submits the data to the server when form is valid
  const onSubmit = useMemo(
    () => (data: T) => {
      setIsSubmittedSuccessfully(true)
      const formData = createFormData(
        { ...data, ...submitData, intent },
        stringifyAllValues
      )
      submit(formData, {
        method: 'POST',
        ...submitConfig,
      })
    },
    [submit, submitConfig, stringifyAllValues, submitData, intent]
  )

  useEffect(() => {
    if (data?.success) {
      onSuccess?.(data, methods)
    }

    if (data && !data.success) {
      onError?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onInvalid = useMemo(() => () => {}, [])

  // React-hook-form uses lazy property getters to avoid re-rendering when properties
  // that aren't being used change. Using getters here preservers that lazy behavior.
  const formState: FormState<T> = useMemo(
    () => ({
      get isDirty() {
        return methods.formState.isDirty
      },
      get isLoading() {
        return methods.formState.isLoading
      },
      get isSubmitted() {
        return methods.formState.isSubmitted
      },
      get isSubmitSuccessful() {
        return isSubmittedSuccessfully || methods.formState.isSubmitSuccessful
      },
      get isSubmitting() {
        return isSubmittingForm || methods.formState.isSubmitting
      },
      get isValidating() {
        return methods.formState.isValidating
      },
      get isValid() {
        return methods.formState.isValid
      },
      get disabled() {
        return methods.formState.disabled
      },
      get submitCount() {
        return methods.formState.submitCount
      },
      get defaultValues() {
        return methods.formState.defaultValues
      },
      get dirtyFields() {
        return methods.formState.dirtyFields
      },
      get touchedFields() {
        return methods.formState.touchedFields
      },
      get validatingFields() {
        return methods.formState.validatingFields
      },
      get errors() {
        return methods.formState.errors
      },
    }),
    [methods.formState, isSubmittedSuccessfully, isSubmittingForm]
  )

  const reset = useMemo(
    () =>
      (
        values?: T | DefaultValues<T> | undefined,
        options?: KeepStateOptions
      ) => {
        setIsSubmittedSuccessfully(false)
        methods.reset(values, options)
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods.reset]
  )

  const register = useMemo(
    () =>
      (
        name: Path<T>,
        options?: RegisterOptions<T> & {
          disableProgressiveEnhancement?: boolean
        }
      ) => ({
        ...methods.register(name, options),
        ...(!options?.disableProgressiveEnhancement && {
          defaultValue: data?.defaultValues?.[name] ?? '',
        }),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods.register, data?.defaultValues]
  )

  const handleSubmit = useMemo(
    () =>
      methods.handleSubmit(
        submitHandlers?.onValid ?? onSubmit,
        submitHandlers?.onInvalid ?? onInvalid
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods.handleSubmit, submitHandlers, onSubmit, onInvalid]
  )

  const hookReturn = useMemo(
    () => ({
      ...methods,
      handleSubmit,
      reset,
      register,
      formState,
    }),
    [methods, handleSubmit, reset, register, formState]
  )

  return hookReturn
}

export const useFormContext = <T extends FieldValues>() => {
  const methods = useReactHookFormContext<T>()
  return {
    ...methods,
    handleSubmit: methods.handleSubmit as any as ReturnType<
      UseFormHandleSubmit<T>
    >,
  }
}
