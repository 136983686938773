import { cn } from '@hapstack/common'
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Icon,
  ScrollArea,
} from '@hapstack/ui'
import { z } from 'zod'

import { useSearchParams } from '../../hooks/useSearchParams'

export const multiSelectParamSchema = z
  .string()
  .default('')
  .transform((v) => (v ? v.split(',').map((id) => Number(id)) : null))
  .transform((arr) => arr?.filter(Boolean))

type MultiSelectOption = {
  id: number | string
  name: string
}

type MultiSelectFilterProps<T extends MultiSelectOption> = {
  options: T[]
  param: string
  displayName?: string
  className?: string
  renderOption?: (option: T) => React.ReactNode
}

export const MultiSelectFilter = <T extends MultiSelectOption>({
  options,
  param,
  displayName,
  className,
  renderOption,
}: MultiSelectFilterProps<T>) => {
  const {
    getSearchParamList,
    addToSearchParamList,
    removeFromSearchParamList,
  } = useSearchParams()
  const selectedOptions = getSearchParamList(param)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="white"
          size="sm"
          className={cn('flex items-center gap-1 capitalize', className)}
        >
          <Icon
            name="filter"
            className={cn(selectedOptions.length > 0 && 'text-accent')}
          />
          {displayName || param}
          {selectedOptions.length > 0 ? (
            <span className="text-sm text-muted-foreground">
              ({selectedOptions.length})
            </span>
          ) : null}
          <Icon
            name="chevron-down"
            size="sm"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56 text-sm"
        align="start"
      >
        <ScrollArea className="h-64">
          {options.map((option) => (
            <DropdownMenuCheckboxItem
              key={option.id}
              onSelect={(e) => e.preventDefault()}
              checked={selectedOptions.includes(String(option.id))}
              onCheckedChange={(checked) => {
                if (checked) {
                  addToSearchParamList(param, String(option.id))
                } else {
                  removeFromSearchParamList(param, String(option.id))
                }
              }}
            >
              {renderOption ? renderOption(option) : option.name}
            </DropdownMenuCheckboxItem>
          ))}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
