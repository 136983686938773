import { createCookieSessionStorage } from '@remix-run/node'
import { serverOnly$ } from 'vite-env-only/macros'
import { z } from 'zod'

import { env } from './env.server'

export const toastSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  variant: z.enum(['success', 'error', 'info']).optional().catch('info'),
  id: z.string().optional(),
})

export type ServerToast = z.infer<typeof toastSchema>

export const createToastSession = serverOnly$((cookieName: string) => {
  const toastSession = createCookieSessionStorage({
    cookie: {
      name: cookieName,
      secrets: [env.COOKIE_SECRET],
      secure: true,
      sameSite: 'lax',
      path: '/',
      maxAge: 60 * 60 * 24 * 30,
      httpOnly: true,
    },
  })

  async function setToast(request: Request, toast?: ServerToast) {
    if (!toast) return undefined
    const session = await toastSession.getSession(request.headers.get('Cookie'))
    session.flash('toast', toast)
    const headers = new Headers()
    headers.append('Set-Cookie', await toastSession.commitSession(session))
    return headers
  }

  async function getToast(request: Request) {
    const session = await toastSession.getSession(request.headers.get('Cookie'))
    const toast = (session.get('toast') as ServerToast) || null
    const headers = new Headers()
    headers.append('Set-Cookie', await toastSession.commitSession(session))
    return { toast, headers }
  }

  return {
    setToast,
    getToast,
  }
})
