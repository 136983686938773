import { useSearchParams as useRemixSearchParams } from '@remix-run/react'
import { useCallback } from 'react'

export function useSearchParams() {
  type NavigateOptions = Parameters<typeof setParams>[1]

  const [params, setParams] = useRemixSearchParams()

  const getSearchParam = useCallback(
    <T extends string>(key: string, schema?: { parse: (value: any) => T }) => {
      const paramValue = params.get(key)
      return schema ? schema.parse(paramValue) : paramValue
    },
    [params]
  )

  const getSearchParamsObject = useCallback(() => {
    return Object.fromEntries(params.entries())
  }, [params])

  const setSearchParam = (
    key: string,
    value: any,
    options?: NavigateOptions
  ) => {
    params.set(key, String(value))
    setParams(params, { replace: true, ...options })
  }

  const setSearchParams = useCallback(
    (
      paramsObject: Record<string, string | number | null>,
      options?: NavigateOptions
    ) => {
      for (const [key, value] of Object.entries(paramsObject)) {
        if (value === null) {
          params.delete(key)
        } else {
          params.set(key, String(value))
        }
      }
      setParams(params, { replace: true, ...options })
    },
    [params, setParams]
  )

  const removeSearchParam = (key: string, options?: NavigateOptions) => {
    params.delete(key)
    setParams(params, { replace: true, ...options })
  }

  const clearSearchParams = (options?: NavigateOptions) => {
    for (const key of params.keys()) {
      params.delete(key)
    }
    setParams(params, { replace: true, ...options })
  }

  const getSearchParamList = useCallback(
    (key: string) => {
      const set = new Set(params.get(key)?.split(',') ?? [])
      return Array.from(set)
    },
    [params]
  )

  const addToSearchParamList = (
    key: string,
    value: any,
    options?: NavigateOptions
  ) => {
    const currentList = new Set(params.get(key)?.split(',') ?? [])
    currentList.add(String(value))
    setSearchParam(key, Array.from(currentList).join(','), options)
  }

  const removeFromSearchParamList = (
    key: string,
    value: any,
    options?: NavigateOptions
  ) => {
    const currentList = new Set(params.get(key)?.split(',') ?? [])
    currentList.delete(String(value))

    if (currentList.size === 0) {
      removeSearchParam(key, options)
      return
    }

    setSearchParam(key, Array.from(currentList).join(','), options)
  }

  return {
    searchParams: params,
    getSearchParam,
    getSearchParamsObject,
    setSearchParams,
    setSearchParam,
    removeSearchParam,
    clearSearchParams,
    getSearchParamList,
    addToSearchParamList,
    removeFromSearchParamList,
  }
}
